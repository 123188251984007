import GameElement from "../elements/GameElement";
export function WhileDown(keyboardKey) {
    return (target, key, descriptor) => {
        GameElement
            .registerWhileKeySubclassFunction(target.constructor, keyboardKey, key, 'whileDown');
        //target.registerTickListener(descriptor.value)
        return descriptor;
    };
}
export function WhileUp(keyboardKey) {
    return (target, key, descriptor) => {
        GameElement
            .registerWhileKeySubclassFunction(target.constructor, keyboardKey, key, 'whileUp');
        //target.registerTickListener(descriptor.value)
        return descriptor;
    };
}
