var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import GameElement from "../../src/engine/elements/GameElement";
import * as THREE from 'three';
import { MathUtils, Vector3 } from "three";
import DefaultGLTFElement from "../../src/engine/elements/DefaultGLTFElement";
import { WhileDown, WhileUp } from "../../src/engine/controls/WhileKey";
import { rotateAroundWorldPoint } from "../../src/engine/utils/rotation";
import { adjustToTickTime } from "../../src/engine/Engine";
export default class Veiculo extends GameElement {
    constructor(positionX, positionZ) {
        super();
        this.comprimentoVeiculo = 1;
        this.veiculo = new DefaultGLTFElement('/sedan.glb', ({ model, helpers: { traverseMaterials } }) => {
            traverseMaterials((m) => m.metalness = 0);
            model.position
                .setX(this.positionX)
                .setZ(this.positionZ);
            this.comprimentoVeiculo =
                model.getObjectByName('wheel_backLeft').position.z - model.getObjectByName('wheel_frontLeft').position.z;
            const pontoCurva = new THREE.Mesh().translateZ(model.getObjectByName('wheel_backLeft').position.z);
            pontoCurva.name = 'pontoCurva';
            model.rotateY(MathUtils.degToRad(-30));
            model.add(pontoCurva);
            return model;
        });
        this.speed = 0;
        this.topSpeed = 0.04;
        this.topSpeedBackwards = -0.02;
        this.turnAngle = 0;
        this.turningAroundPoint = new Vector3(0, 0, 0);
        this.positionX = positionX;
        this.positionZ = positionZ;
        //this.pontoCurva.position.copy(this.turningAroundPoint)
    }
    front() {
        if (!this.veiculo.isLoaded)
            return;
        this.speed += adjustToTickTime(0.001);
        if (this.speed > this.topSpeed)
            this.speed = this.topSpeed;
    }
    stopFront() {
        if (!this.veiculo.isLoaded)
            return;
        if (this.speed > 0) {
            this.speed -= adjustToTickTime(0.0002);
            if (this.speed < 0)
                this.speed = 0;
        }
    }
    backwards() {
        if (!this.veiculo.isLoaded)
            return;
        this.speed -= adjustToTickTime(0.001);
        if (this.speed < this.topSpeedBackwards)
            this.speed = this.topSpeedBackwards;
    }
    stopBackwards() {
        if (!this.veiculo.isLoaded)
            return;
        if (this.speed < 0) {
            this.speed += adjustToTickTime(0.0002);
            if (this.speed > 0)
                this.speed = 0;
        }
    }
    turnLeft() {
        this.turnAngle += adjustToTickTime(0.013);
        if (this.turnAngle > 0.45)
            this.turnAngle = 0.45;
        this.computeTurningAroundPoint();
    }
    turnRight() {
        this.turnAngle -= adjustToTickTime(0.013);
        if (this.turnAngle < -0.45)
            this.turnAngle = -0.45;
        this.computeTurningAroundPoint();
    }
    computeTurningAroundPoint() {
        if (this.turnAngle < 0.001 && this.turnAngle > -0.001)
            this.turnAngle = 0;
        this.turningAroundPoint.x = this.comprimentoVeiculo / Math.tan(-this.turnAngle);
        //this.turningAroundPoint.
        this.veiculo.get().getObjectByName('pontoCurva').position.x = this.comprimentoVeiculo / Math.tan(-this.turnAngle);
        //this.turningAroundPoint.x = this.veiculo.getMesh().getObjectByName('wheel_frontLeft').get
        //this.pontoCurva.position.copy(this.turningAroundPoint)
    }
    tick() {
        if (this.veiculo.isLoaded) {
            if (this.turnAngle) {
                rotateAroundWorldPoint(this.veiculo.get(), this.veiculo.get().getObjectByName('pontoCurva').getWorldPosition(new THREE.Vector3()), new THREE.Vector3(0, 1, 0), { distance: adjustToTickTime(this.turnAngle > 0 ? this.speed : -this.speed) });
            }
            else {
                this.veiculo.get().translateZ(adjustToTickTime(-this.speed));
            }
            if (this.speed > 0 && this.turnAngle) {
                this.turnAngle -= adjustToTickTime(this.turnAngle / 60);
                this.computeTurningAroundPoint();
            }
            this.veiculo.get().getObjectByName('wheel_frontLeft').rotation.y = this.turnAngle;
            this.veiculo.get().getObjectByName('wheel_frontRight').rotation.y = this.turnAngle;
        }
    }
    render() {
        return [
            this.veiculo
        ];
    }
}
__decorate([
    WhileDown('w')
], Veiculo.prototype, "front", null);
__decorate([
    WhileUp('w')
], Veiculo.prototype, "stopFront", null);
__decorate([
    WhileDown('s')
], Veiculo.prototype, "backwards", null);
__decorate([
    WhileUp('s')
], Veiculo.prototype, "stopBackwards", null);
__decorate([
    WhileDown('a')
], Veiculo.prototype, "turnLeft", null);
__decorate([
    WhileDown('d')
], Veiculo.prototype, "turnRight", null);
